var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-post"},[_c('h1',[_vm._v("Dodaj wpisy")]),_c('el-form',{ref:"addPost",staticClass:"add-post-form",attrs:{"model":_vm.addPost,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Tytuł","prop":"title","rules":[
        { required: true, message: 'Tytuł jest wymagany'},
      ]}},[_c('el-input',{attrs:{"type":"title","autocomplete":"off"},model:{value:(_vm.addPost.title),callback:function ($$v) {_vm.$set(_vm.addPost, "title", $$v)},expression:"addPost.title"}})],1),_c('el-form-item',{attrs:{"label":"Zdjęcie","prop":"image","rules":[
        { required: true, message: 'Zdjęcie jest wymagane'},
      ]}},[_c('el-upload',{staticClass:"upload",attrs:{"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"on-change":_vm.handleChangeImages,"auto-upload":false,"limit":1,"file-list":_vm.fileList}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("Dodaj zdjęcie")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("jpg/png zdjęcia nie większe niż 500kb")])],1),_c('el-dialog',{attrs:{"visible":_vm.previewDialogVisible},on:{"update:visible":function($event){_vm.previewDialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.previewDialogUrl,"alt":""}})])],1),_c('el-form-item',{attrs:{"label":"Treść","prop":"content","rules":[
        { required: true, message: 'Treść jest wymagana'},
      ]}},[_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.addPost.content),callback:function ($$v) {_vm.$set(_vm.addPost, "content", $$v)},expression:"addPost.content"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Zapisz")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }